(function () {
    const scriptTag = document.getElementById('tutorial-js') as HTMLScriptElement | null;
    if (!scriptTag) {
        console.warn('cannot determine image directory');
        return;
    }
    let imagesDir = scriptTag.dataset.images;
    if (!imagesDir) {
        const path = scriptTag.src.split('?')[0]; // remove any ?query
        imagesDir = path.split('/').slice(0, -1).join('/');
        if (!imagesDir) {
            imagesDir = './';
        }
    }
    WGo.DIR = imagesDir.endsWith('/') ? imagesDir : imagesDir + '/';

    // reset images url with new dir
    WGo.Board.default.background = WGo.DIR + 'wood_1024.jpg';
    WGo.Board.default.whiteStoneGraphic = [
        WGo.DIR + 'stones/white00_128.png',
        WGo.DIR + 'stones/white01_128.png',
        WGo.DIR + 'stones/white02_128.png',
        WGo.DIR + 'stones/white03_128.png',
        WGo.DIR + 'stones/white04_128.png',
        WGo.DIR + 'stones/white05_128.png',
        WGo.DIR + 'stones/white06_128.png',
        WGo.DIR + 'stones/white07_128.png',
        WGo.DIR + 'stones/white08_128.png',
        WGo.DIR + 'stones/white09_128.png',
        WGo.DIR + 'stones/white10_128.png',
    ];
    WGo.Board.default.blackStoneGraphic = [
        WGo.DIR + 'stones/black00_128.png',
        WGo.DIR + 'stones/black01_128.png',
        WGo.DIR + 'stones/black02_128.png',
        WGo.DIR + 'stones/black03_128.png',
    ];
})();
