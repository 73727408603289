import { KifuMarkup } from 'wgo/wgo/kifu';
import { BoardConfig, Game, GoColor } from 'wgo/wgo/wgo';

import invertColor from '../utils/invertColor';
import AnimatedBoard from './AnimatedBoard';
import coordinatesDrawer from './custom/coordinatesDrawer';
import InvalidPlayError from './InvalidPlayError';

export default class AnimatedGame {
    public readonly board: AnimatedBoard;
    public game: Game;
    private blackCaptured: number;
    private whiteCaptured: number;

    public constructor(element: Element, config: BoardConfig) {
        const board = new WGo.Board(element, config);
        this.board = new AnimatedBoard(board);
        this.board.addCustomObject(coordinatesDrawer);
        this.game = new WGo.Game(board.size);
        this.blackCaptured = 0;
        this.whiteCaptured = 0;
    }

    public play(x: number, y: number, c: GoColor): void {
        const result = this.game.play(x, y, c);
        if (Array.isArray(result)) {
            this.board.placeStone(x, y, c);
            result.forEach(({ x, y }) => this.board.removeStone(x, y, invertColor(c)));
            this.addCaptured(c, result.length);
        } else {
            throw InvalidPlayError.create(result);
        }
    }

    public skipAnimation(): Promise<void> {
        return this.board.skipAnimation();
    }

    public waitAnimation(): Promise<void> {
        return this.board.waitAnimation();
    }

    public placeStone(x: number, y: number, c: GoColor): void {
        this.board.addObject({ x, y, c });
        this.game.addStone(x, y, c);
    }

    public reset(): void {
        this.board.removeAllObjects();
        this.game = new WGo.Game(this.board.board.size);
        this.blackCaptured = 0;
        this.whiteCaptured = 0;
    }

    public redraw(): void {
        this.board.redraw();
    }

    public getCaptured(color: GoColor): number {
        return color === WGo.B ? this.blackCaptured : this.whiteCaptured;
    }

    private addCaptured(color: GoColor, newCaptured: number): void {
        if (color === WGo.B) {
            this.blackCaptured += newCaptured;
        } else {
            this.whiteCaptured += newCaptured;
        }
    }

    public addMarkup(markup: KifuMarkup): void {
        this.board.addObject(markup);
    }

    public removeMarkup(markup: KifuMarkup): void {
        this.board.removeObject(markup);
    }
}
