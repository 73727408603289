import { Kifu, KifuInfo, KNode } from 'wgo/wgo/kifu';
import { Board, BoardConfig, BoardObject, BoardObjectRemoveSpecifier, Game } from 'wgo/wgo/wgo';

import { defaultBoardWidth } from '../const';
import { zIndex } from '../styles/const';
import invertColor from '../utils/invertColor';
import coordinatesDrawer, { coordinatesSectionConfig } from './custom/coordinatesDrawer';
import LastRemovedDrawHandler from './objects/LastRemovedDrawHandler';

const defaultConfig: BoardConfig = {
    // starPoints: { 9: [{ x: 4, y: 4 }] },
    section: coordinatesSectionConfig,
}; // FIXME same with SgfGame

interface PlaceContext {
    depth: number;
    showNumber: boolean;
    showCaptured: boolean;
}

function markupTypeToObjectType(type: 'CR' | 'SQ' | 'TR' | 'SL' | 'MA'): 'CR' | 'SQ' | 'TR' | 'MA' {
    if (type === 'SL') {
        // selected
        return 'CR';
    } else {
        return type;
    }
}

export default class SgfPlayer {
    private static editContext(current: KNode | KifuInfo, context: PlaceContext): void {
        if (current.PM !== undefined) {
            context.showNumber = current.PM === '1';
        }
        if (current.FG !== undefined) {
            const flags = Number(current.FG);
            context.showCaptured = !(flags & 0x100);
        }
    }

    protected readonly game: Game;
    protected readonly board: Board;
    protected readonly sgf: Kifu;
    protected readonly root: KNode;
    protected transientObjects: BoardObjectRemoveSpecifier[] = [];

    public constructor(element: HTMLElement, sgf?: string) {
        this.sgf = WGo.SGF.parse(sgf || element.innerText);
        element.innerText = '';
        const width = Number(element.dataset.tutorialWidth || defaultBoardWidth);

        WGo.Board.drawHandlers.LAST_REMOVED = LastRemovedDrawHandler;
        const config = { ...defaultConfig, size: this.sgf.size, width };
        this.board = new WGo.Board(element, config);
        this.game = new WGo.Game(config.size);
        const overlayLayer = new WGo.Board.CanvasLayer();
        this.board.overlay = overlayLayer;
        this.board.addLayer(overlayLayer, zIndex.gameOverlayLayer);
        this.board.addCustomObject(coordinatesDrawer);

        this.root = this.sgf.root;

        this.draw();
    }

    public draw(): void {
        let current: KNode | undefined = this.root;
        const context: PlaceContext = { depth: 0, showNumber: true, showCaptured: true };
        SgfPlayer.editContext(this.sgf.info, context);

        while (current !== undefined) {
            this.transientObjects.forEach((obj) => this.board.removeObject(obj));
            this.transientObjects = [];

            SgfPlayer.editContext(current, context);
            this.placeSetup(current);
            if (current.move) {
                context.depth++;
                this.placeMove(current, context);
            }
            this.placeMarkups(current);
            current = current.children[0];
        }
    }

    public redraw(): void {
        this.board.redraw();
    }

    private placeSetup(current: KNode): void {
        if (current.setup) {
            current.setup.forEach(({ c, x, y }) => {
                if (c) {
                    this.game.addStone(x, y, c);
                    this.board.addObject({ x, y, c });
                } else {
                    this.game.removeStone(x, y);
                    this.board.removeObject({ x, y });
                }
            });
        }
    }

    private placeMove(current: KNode, context: PlaceContext): void {
        if (current.move) {
            const { c, x, y } = current.move;
            const result = this.game.play(x, y, c);
            if (Array.isArray(result)) {
                result.forEach((removed) => {
                    this.board.removeObject({ x: removed.x, y: removed.y });
                    if (context.showCaptured) {
                        const transientObject: BoardObject<'LAST_REMOVED'> = {
                            x: removed.x,
                            y: removed.y,
                            c: invertColor(c),
                            type: 'LAST_REMOVED',
                        };
                        this.board.addObject(transientObject);
                        this.transientObjects.push(transientObject);
                    }
                });
                this.board.addObject({ x, y, c });
                if (context.showNumber) {
                    this.board.addObject({ x, y, type: 'LB', text: String(context.depth) });
                }
            } else {
                // FIXME
            }
        }
    }

    private placeMarkups(current: KNode): void {
        if (current.markup) {
            current.markup.forEach(({ type, x, y }) => {
                const object = { x, y, type: markupTypeToObjectType(type) } as const;
                this.transientObjects.push(object);
                return this.board.addObject(object);
            });
        }
    }
}
