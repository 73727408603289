export const space = {
    section: '2rem',
    default: '1rem',
    near: '0.5rem',
    linked: '0.25rem',
};

// from bootstrap
export const palette = {
    white: '#fff',
    vividRed: '#ef4949',
    red: '#dc3545',
    blue: '#007bff',
    yellow: '#ffc107',
    green: '#28a745',
    cyan: '#17a2b8',
    black: '#000',
};

export const color = {
    border: 'lightgray',
    error: palette.red,
    disabled: 'gray',
    diminished: '#666',
    navBar: '#f8f9fa',
    hover: '#e8e9ea',
    popupBackdrop: 'rgba(0,0,0,0.7)',
};

export const theme = {
    primary: palette.blue,
    secondary: '#6c757d',
    warning: palette.yellow,
    danger: palette.red,
    success: palette.green,
    info: palette.cyan,
};

export type ThemeVariant = keyof typeof theme;

export const fontSize = {
    title: 'x-large',
    diminished: 'small',
};

export const borderRadius = {
    small: '0.25rem',
    large: '1rem',
};

export const zIndex = {
    menu: 1000,
    popup: 1010,
    popupBackdrop: 999,
    gameOverlayLayer: 500,
};
