interface BoardRedrawContract {
    redraw(): void;
}

type ImageType = string | HTMLImageElement;

function loadImage(arr: ImageType[], index: number, file: ImageType): Promise<void> {
    return new Promise((resolve) => {
        let image: HTMLImageElement;
        if (typeof file === 'string') {
            image = new Image();
            image.src = file;
            arr[index] = image;
        } else {
            image = file;
        }
        image.addEventListener('load', () => resolve(), false);
    });
}

function loadImages(arr: ImageType[]): Promise<void[]> {
    return Promise.all(arr.map((image, index) => loadImage(arr, index, image)));
}

export default class StoneImagePreloader {
    private readonly boards: BoardRedrawContract[] = [];

    public register(board: BoardRedrawContract): void {
        this.boards.push(board);
    }

    public preload(): void {
        Promise.all([
            loadImages(WGo.Board.default.blackStoneGraphic),
            loadImages(WGo.Board.default.whiteStoneGraphic),
        ]).then(() => {
            this.redraw();
        });
    }

    public redraw(): void {
        this.boards.forEach((board) => board.redraw());
    }
}
