import { BoardObject, DrawHandler } from 'wgo/wgo/wgo';

export const stoneAnimationFrames = 60;

const AnimatedStoneHandler: DrawHandler<BoardObject<'ANIMATION'>> = {
    stone: {
        draw(params: BoardObject<'ANIMATION'>, board) {
            const parentDraw = board.stoneHandler.stone?.draw;
            if (parentDraw) {
                const savedAlpha = this.globalAlpha;
                this.globalAlpha = params.remainFrame / stoneAnimationFrames;
                parentDraw.call(this, params, board);
                this.globalAlpha = savedAlpha;
            }
            params.remainFrame--;
        },
    },
    shadow: {
        draw(params: BoardObject<'ANIMATION'>, board) {
            const parentDraw = board.stoneHandler.shadow?.draw;
            if (parentDraw) {
                const savedAlpha = this.globalAlpha;
                this.globalAlpha = params.remainFrame / stoneAnimationFrames;
                parentDraw.call(this, params, board);
                this.globalAlpha = savedAlpha;
            }
        },
    },
};
export default AnimatedStoneHandler;
