import 'wgo/wgo/wgo'; // require for global definition
import '../patch/fixWgoDir';
import 'wgo/wgo/sgfparser';
import 'wgo/wgo/kifu';

import SgfGame from '../game/SgfGame';
import SgfPlayer from '../game/SgfPlayer';
import { castElementToHtmlElement } from '../utils/castElement';
import StoneImagePreloader from '../utils/StoneImagePreloader';

const preloader = new StoneImagePreloader();

window.addEventListener('load', () => {
    console.log(`go-tutorial: ${VERSION} loaded`);
    preloader.preload();
});

window.addEventListener('load', () => {
    const targetElements = document.getElementsByClassName('go-tutorial');
    for (let i = 0; i < targetElements.length; i++) {
        const element = castElementToHtmlElement(targetElements[i]);
        if (element) {
            preloader.register(new SgfGame(element));
        }
    }
});

window.addEventListener('load', () => {
    const targetElements = document.getElementsByClassName('go-tutorial-board');
    for (let i = 0; i < targetElements.length; i++) {
        const element = castElementToHtmlElement(targetElements[i]);
        if (element) {
            preloader.register(new SgfPlayer(element));
        }
    }
});
