import { Board, BoardObject, DrawHandler, DrawHandlers, EventListener, GoColor } from 'wgo/wgo/wgo';

import AnimatedStoneHandler, { stoneAnimationFrames } from './objects/AnimatedStoneHandler';

export default class AnimatedBoard {
    public readonly board: Board;
    private removingObject: BoardObject<'ANIMATION'>[] = [];

    public constructor(board: Board) {
        WGo.Board.drawHandlers.ANIMATION = AnimatedStoneHandler;
        this.board = board;
    }

    public addEventListener(name: string, callback: EventListener): void {
        this.board.addEventListener(name, callback);
    }

    public addObject<TypeName extends keyof DrawHandlers = 'NORMAL'>(object: BoardObject<TypeName>): void {
        this.board.addObject(object);
    }

    public placeStone(x: number, y: number, c: GoColor): void {
        this.board.addObject({ x, y, c });
    }

    public removeStone(x: number, y: number, c: GoColor): void {
        const animation: BoardObject<'ANIMATION'> = {
            x,
            y,
            c,
            remainFrame: stoneAnimationFrames,
            type: 'ANIMATION',
        };
        this.board.removeObject({ x, y });
        this.board.addObject(animation);
        this.removingObject.push(animation);
    }

    public removeObject<ObjectType>(object: BoardObject<ObjectType>): void {
        this.board.removeObject(object);
    }

    public removeAllObjects(): void {
        this.board.removeAllObjects();
    }

    public isAnimating(): boolean {
        return this.removingObject.length > 0;
    }

    public async waitAnimation(): Promise<void> {
        while (this.removingObject.length) {
            await this.nextAnimation();
        }
    }

    private nextAnimation(): Promise<void> {
        return new Promise((resolve) => {
            requestAnimationFrame(() => {
                if (this.removingObject.length && this.removingObject[0].remainFrame <= 0) {
                    this.removingObject.forEach((obj) => this.board.removeObject(obj));
                    this.removingObject = [];
                }
                this.board.redraw();
                resolve();
            });
        });
    }

    /**
     * アニメーションをスキップする
     *
     * 1フレームwaitあり。
     */
    public skipAnimation(): Promise<void> {
        this.removingObject.forEach((obj) => this.board.removeObject(obj));
        this.removingObject = [];
        return this.nextAnimation();
    }

    public addCustomObject<Params extends object = {}>(handler: DrawHandler<Params>): void {
        this.board.addCustomObject(handler);
    }

    public redraw(): void {
        this.board.redraw();
    }
}
