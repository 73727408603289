import { GoColor } from 'wgo/wgo/wgo';

import { palette } from '../styles/const';
import assertUnreachable from '../utils/assertUnreachable';
import GameResult from './GameResult';

function getResultColor(result: GameResult): string {
    switch (result) {
        case GameResult.error:
        case GameResult.onGame:
            return 'black';
        case GameResult.won:
            return '#3333aa';
        case GameResult.lost:
            return '#aa3333';
        default:
            assertUnreachable(result);
    }
}

function getDefaultText(result: GameResult): string | undefined {
    switch (result) {
        case GameResult.error:
        case GameResult.onGame:
            return undefined;
        case GameResult.won:
            return '成功';
        case GameResult.lost:
            return '失敗';
        default:
            assertUnreachable(result);
    }
}

export default class SgfGameControlElements {
    private static createContainer(): HTMLDivElement {
        const container = document.createElement('div');
        container.style.display = 'flex';
        container.style.flexDirection = 'column';
        container.className = 'go-tutorial-container';
        return container;
    }

    private static createCaptureContainer(): HTMLDivElement {
        const container = document.createElement('div');
        container.style.display = 'flex';
        container.style.flexDirection = 'row';
        container.style.alignItems = 'center';
        container.className = 'go-tutorial-capture-container';
        return container;
    }

    private static createRetryButton(): HTMLButtonElement {
        const button = document.createElement('button');
        button.innerText = 'やり直し';
        button.className = 'go-tutorial-retry-button';
        return button;
    }

    private static createRetryButtonContainer(): HTMLDivElement {
        const div = document.createElement('div');
        div.className = 'go-tutorial-retry-container';
        return div;
    }

    private static createCapturedImage(isBlack: boolean): SVGElement {
        const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
        svg.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink');
        svg.setAttributeNS(null, 'viewBox', '0 0 64 64');
        svg.setAttributeNS(null, 'width', '32');
        svg.setAttributeNS(null, 'height', '32');

        const title = document.createElementNS('http://www.w3.org/2000/svg', 'title');
        title.innerHTML = `${isBlack ? '黒' : '白'}石の取られた数`;

        const image = document.createElementNS('http://www.w3.org/2000/svg', 'image');
        image.setAttributeNS(null, 'height', '64');
        image.setAttributeNS(null, 'width', '64');
        image.setAttributeNS(
            'http://www.w3.org/1999/xlink',
            'href',
            isBlack ? WGo.DIR + '/black_128.png' : WGo.DIR + '/white_128.png'
        );

        const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        path.setAttributeNS(null, 'd', 'M10,10L54,54M54,10L10,54');
        path.setAttributeNS(null, 'stroke', palette.vividRed);
        path.setAttributeNS(null, 'stroke-width', '6');

        svg.appendChild(title);
        svg.appendChild(image);
        svg.appendChild(path);
        return svg;
    }

    private static createCapturedCount(): HTMLDivElement {
        const p = document.createElement('div');
        p.innerText = '0';
        p.className = 'go-tutorial-captured';
        return p;
    }

    private static createCapturedIndividualContainer(isBlack: boolean): HTMLDivElement {
        const container = document.createElement('div');
        const baseName = 'go-tutorial-captured-individual';
        const modifier = isBlack ? '--black' : '--white';
        container.className = `${baseName} ${baseName}${modifier}`;
        container.style.display = 'flex';
        container.style.flexDirection = 'row';
        container.style.alignItems = 'center';
        return container;
    }

    private static createResult(): HTMLParagraphElement {
        const p = document.createElement('p');
        p.innerText = '';
        p.className = 'go-tutorial-result';
        return p;
    }

    public readonly retryButton: HTMLButtonElement;
    // *黒*がcaptureした*白*石
    private readonly blackCaptured: HTMLParagraphElement;
    private readonly whiteCaptured: HTMLParagraphElement;
    private readonly result: HTMLParagraphElement;

    public constructor(element: HTMLElement) {
        const container = SgfGameControlElements.createContainer();

        const retryContainer = SgfGameControlElements.createRetryButtonContainer();
        this.retryButton = SgfGameControlElements.createRetryButton();
        retryContainer.appendChild(this.retryButton);
        container.appendChild(retryContainer);

        const captureContainer = SgfGameControlElements.createCaptureContainer();

        const capturedWhiteContainer = SgfGameControlElements.createCapturedIndividualContainer(false);
        capturedWhiteContainer.appendChild(SgfGameControlElements.createCapturedImage(false));
        this.blackCaptured = SgfGameControlElements.createCapturedCount();
        capturedWhiteContainer.appendChild(this.blackCaptured);
        captureContainer.appendChild(capturedWhiteContainer);

        const captureBlackContainer = SgfGameControlElements.createCapturedIndividualContainer(true);
        captureBlackContainer.appendChild(SgfGameControlElements.createCapturedImage(true));
        this.whiteCaptured = SgfGameControlElements.createCapturedCount();
        captureBlackContainer.appendChild(this.whiteCaptured);
        captureContainer.appendChild(captureBlackContainer);
        container.appendChild(captureContainer);

        this.result = SgfGameControlElements.createResult();
        container.appendChild(this.result);

        element.appendChild(container);
    }

    public setCaptured(color: GoColor, count: number): void {
        if (color === WGo.W) {
            this.whiteCaptured.innerText = String(count);
        } else {
            this.blackCaptured.innerText = String(count);
        }
    }

    public setResult(result: GameResult, text: string | undefined): void {
        if (Array.isArray(text)) {
            text = text.join(',');
        }
        this.result.style.color = getResultColor(result);
        this.result.innerText = text || getDefaultText(result) || '';
    }

    public reset(): void {
        this.setCaptured(WGo.B, 0);
        this.setCaptured(WGo.W, 0);
        this.setResult(GameResult.onGame, '');
    }
}
