import { BoardConfig, DrawHandler } from 'wgo/wgo/wgo';

export function coordinatesXLabel(x: number): string {
    return String(x + 1);
}
export function coordinatesYLabel(y: number): string {
    let ch = y + 'A'.charCodeAt(0);
    if (ch >= 'I'.charCodeAt(0)) {
        ch++;
    }
    return String.fromCharCode(ch);
}
export const coordinatesSectionConfig: BoardConfig['section'] = {
    top: 0,
    left: 0,
    right: -0.5,
    bottom: -0.5,
};

const coordinatesDrawer: DrawHandler = {
    grid: {
        draw: function (args, board) {
            const oldFillStyle = this.fillStyle;
            this.fillStyle = 'rgba(0,0,0,0.7)';
            this.textBaseline = 'middle';
            this.textAlign = 'center';
            this.font = board.stoneRadius + 'px ' + (board.font || '');

            // const xleft = board.getX(-0.75);
            const xright = board.getX(board.size - 0.25);
            // const ytop = board.getY(-0.75);
            const ybottom = board.getY(board.size - 0.25);

            for (let i = 0; i < board.size; i++) {
                let t = board.getY(i);
                this.fillText(coordinatesYLabel(i), xright, t);
                // this.fillText(board.size - i, xleft, t);

                t = board.getX(i);
                // this.fillText(String.fromCharCode(ch), t, ytop);
                this.fillText(coordinatesXLabel(i), t, ybottom);
            }

            this.fillStyle = oldFillStyle;
        },
    },
};

export default coordinatesDrawer;
