import { Kifu, KifuMarkup, KifuMove, KNode } from 'wgo/wgo/kifu';
import { BoardConfig, GameInvalidPlayCode, GoColor } from 'wgo/wgo/wgo';

import { defaultBoardWidth } from '../const';
import assertUnreachable from '../utils/assertUnreachable';
import CriticalSection from '../utils/CriticalSection';
import invertColor from '../utils/invertColor';
import AnimatedGame from './AnimatedGame';
import { coordinatesSectionConfig } from './custom/coordinatesDrawer';
import GameResult from './GameResult';
import InvalidPlayError from './InvalidPlayError';
import SgfGameControlElements from './SgfGameControlElements';

const defaultConfig: BoardConfig = {
    width: 400,
    size: 9,
    section: coordinatesSectionConfig,
};

function extractWinnerFromNode(tree: KNode): GoColor | undefined {
    if (tree.GB === '2') {
        return GoColor.B;
    } else if (tree.GW === '2') {
        return GoColor.W;
    } else {
        return undefined;
    }
}

function extractGameResult(tree: KNode, playerColor: GoColor): GameResult {
    const winner = extractWinnerFromNode(tree);
    if (!winner) {
        return GameResult.onGame;
    } else if (winner === playerColor) {
        return GameResult.won;
    } else {
        return GameResult.lost;
    }
}

function sleep(seconds: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
}

function defaultResultText(result: GameResult): string {
    switch (result) {
        case GameResult.error: // FIXME
        case GameResult.onGame:
            return '';
        case GameResult.won:
            return 'あなたの勝ち！';
        case GameResult.lost:
            return 'あなたの負け！';
        default:
            assertUnreachable(result);
    }
}

export default class SgfGame {
    protected readonly lock: CriticalSection;
    protected readonly game: AnimatedGame;
    protected readonly sgf: Kifu;
    protected readonly el: SgfGameControlElements;
    protected readonly playerColor: GoColor;
    protected current: KNode | null;
    protected currentMarkups: KifuMarkup[] = [];
    protected resetting = false;

    public constructor(element: HTMLElement, sgf?: string, container?: HTMLElement) {
        this.lock = new CriticalSection();
        this.sgf = WGo.SGF.parse(sgf || element.innerText);
        element.innerText = '';
        this.playerColor = this.sgf.root.turn || GoColor.B;
        const width = Number(element.dataset.tutorialWidth || defaultBoardWidth);

        const config = { ...defaultConfig, size: this.sgf.size, width };
        this.game = new AnimatedGame(element, config);
        this.game.board.addEventListener('click', this.handleClick.bind(this));
        this.current = this.sgf.root;
        if (!container) {
            element.style.display = 'flex';
        }
        this.el = new SgfGameControlElements(container || element);
        this.el.retryButton.addEventListener('click', this.waitAndReset.bind(this));

        this.reset();
    }

    private async waitAndReset(): Promise<void> {
        this.resetting = true;
        await this.game.skipAnimation();
        this.reset();
        this.lock.runSequential(() => this.reset()).catch(console.warn);
    }

    public reset(): void {
        this.game.reset();
        this.current = this.sgf.root;
        this.currentMarkups = [];
        this.el.reset();
        this.resetting = false;
        if (this.current.setup) {
            this.current.setup.map((move) => this.placeStone(move));
        }
        this.placeMarkups(this.current.markup);
        this.updateResult();
    }

    public redraw(): void {
        this.game.redraw();
    }

    protected handleClick(x: number, y: number): void {
        this.lock
            .onlyOne(async () => {
                if (!this.current) {
                    return;
                }
                try {
                    await this.playPlayer(x, y);
                    if (!this.resetting) {
                        await sleep(1);
                    }
                    if (!this.resetting) {
                        await this.playEnemy();
                    }
                } catch (e) {
                    if (InvalidPlayError.isInstance(e)) {
                        if (e.code !== GameInvalidPlayCode.NoCoordinatesOnBoard) {
                            this.el.setResult(GameResult.error, e.message);
                        }
                    }
                    throw e;
                }
            })
            .catch(console.error);
    }

    protected async playPlayer(x: number, y: number): Promise<void> {
        let next: KNode | undefined;
        let fallback: KNode | undefined;
        this.current?.children.forEach((tree) => {
            const move = tree.move;
            if (move) {
                if (move.c === this.playerColor) {
                    if (move.x === x && move.y === y) {
                        next = tree;
                    }
                } else {
                    fallback = tree;
                }
            }
        });
        this.game.play(x, y, this.playerColor);
        if (next) {
            this.current = next;
        } else if (!fallback) {
            console.error('cannot select node!', this.current);
            return; // FIXME
        }
        this.placeMarkups((next || fallback)?.markup);
        this.updateResult();
        await this.game.waitAnimation();

        if (next) {
            this.current = next.children[0] || null;
        } else if (fallback) {
            this.current = fallback;
        }
    }

    protected async playEnemy(): Promise<void> {
        if (this.current?.move) {
            this.game.play(this.current.move.x, this.current.move.y, invertColor(this.playerColor));
            this.placeMarkups(this.current.markup);
            this.updateResult();
            if (this.current.children.length === 0) {
                this.current = null; // end the game
            }
            await this.game.waitAnimation();
        }
    }

    protected placeStone(move: KifuMove): void {
        if (move.c) {
            this.game.placeStone(move.x, move.y, move.c);
        } else {
            console.warn({ warning: 'not supported erasure', move });
        }
    }

    protected updateResult(): void {
        if (this.current) {
            const result = extractGameResult(this.current, this.playerColor);
            this.el.setResult(result, this.current.comment || defaultResultText(result));
            this.el.setCaptured(GoColor.B, this.game.getCaptured(GoColor.B));
            this.el.setCaptured(GoColor.W, this.game.getCaptured(GoColor.W));
        }
    }

    private placeMarkups(markup: KifuMarkup[] | undefined): void {
        this.currentMarkups.forEach((markup) => this.game.removeMarkup(markup));
        const newMarkups = markup || [];
        newMarkups.forEach((markup) => this.game.addMarkup(markup));
        console.log({ newMarkups, current: this.currentMarkups });
        this.currentMarkups = newMarkups;
    }
}
