export default class CriticalSection {
    protected acquired: boolean;
    protected waitingResolvers: (() => void)[];

    public constructor() {
        this.acquired = false;
        this.waitingResolvers = [];
    }

    protected acquire(): Promise<void> {
        if (!this.acquired) {
            this.acquired = true;
            return Promise.resolve();
        }
        return new Promise((resolve): void => {
            this.waitingResolvers.push(resolve);
        });
    }

    protected tryAcquire(): boolean {
        if (!this.acquired) {
            this.acquired = true;
            return true;
        }
        return false;
    }

    protected release(): void {
        const resolve = this.waitingResolvers.shift();
        if (resolve) {
            resolve();
        } else {
            this.acquired = false;
        }
    }

    public async runSequential<T>(job: () => T | Promise<T>): Promise<T> {
        await this.acquire();
        try {
            return await job();
        } finally {
            this.release();
        }
    }

    public async onlyOne<T>(job: () => T | Promise<T>): Promise<T | undefined> {
        if (this.tryAcquire()) {
            try {
                return await job();
            } finally {
                this.release();
            }
        }
    }

    public async lastOne<T, U>(job: () => T | Promise<T>, postjob: (arg: T) => U | Promise<U>): Promise<U | undefined> {
        if (!this.tryAcquire()) {
            await this.acquire();
            if (this.waitingResolvers.length > 0) {
                // waiters exist: not last one
                this.release();
                return;
            }
        }
        // lock acquired
        try {
            const result = await job();
            if (this.waitingResolvers.length === 0) {
                // if no other jobs
                return await postjob(result);
            }
            return undefined;
        } finally {
            this.release();
        }
    }
}
