import { GameInvalidPlayCode } from 'wgo/wgo/wgo';

const errorName = 'InvalidPlayError';

export default class InvalidPlayError extends Error {
    public static getMessage(code: GameInvalidPlayCode): string {
        switch (code) {
            case GameInvalidPlayCode.AlreadyStonePlaced:
                return 'すでに石が置かれています';
            case GameInvalidPlayCode.NoCoordinatesOnBoard:
                return '位置が不正です';
            case GameInvalidPlayCode.Repeated:
                return '着手禁止（コウ）';
            case GameInvalidPlayCode.Suicide:
                return '着手禁止（自殺）';
            default:
                // assertUnreachable(code);
                throw new Error('不明なエラーコード');
        }
    }

    public static create(code: GameInvalidPlayCode): InvalidPlayError {
        return new InvalidPlayError(InvalidPlayError.getMessage(code), code);
    }

    public static isInstance(r: Error): r is InvalidPlayError {
        return r.name === errorName;
    }

    public readonly code: GameInvalidPlayCode;

    public constructor(message: string, code: GameInvalidPlayCode) {
        super(message);
        this.name = errorName;
        this.code = code;
    }
}
