import { BoardObject, DrawHandler, GoColor } from 'wgo/wgo/wgo';

const alpha = 0.3;

const LastRemovedDrawHandler: DrawHandler<BoardObject<'LAST_REMOVED'>> = {
    overlay: {
        draw(params: BoardObject<'LAST_REMOVED'>, board) {
            const cx = board.getX(params.x);
            const cy = board.getY(params.y);
            const radius = board.stoneRadius;
            this.lineWidth = radius / 6;
            if (params.c === GoColor.B) {
                this.strokeStyle = 'white';
            } else {
                this.strokeStyle = 'black';
            }
            this.globalAlpha = alpha;
            const halfLength = (radius * 3) / 4;
            this.beginPath();
            this.moveTo(cx - halfLength, cy - halfLength);
            this.lineTo(cx + halfLength, cy + halfLength);
            this.moveTo(cx - halfLength, cy + halfLength);
            this.lineTo(cx + halfLength, cy - halfLength);
            this.stroke();
            this.globalAlpha = 1.0;
        },
        clear(params: BoardObject<'LAST_REMOVED'>, board) {
            const xr = board.getX(params.x);
            const yr = board.getY(params.y);
            const sr = board.stoneRadius;
            this.clearRect(xr - sr, yr - sr, 2 * sr, 2 * sr);
        },
    },
    stone: {
        draw(params, board) {
            const parentDraw = board.stoneHandler.stone?.draw;
            if (parentDraw) {
                this.globalAlpha = alpha;
                parentDraw.call(this, params, board);
                this.globalAlpha = 1.0;
            }
        },
    },
    shadow: {
        draw(params, board) {
            const parentDraw = board.stoneHandler.shadow?.draw;
            if (parentDraw) {
                this.globalAlpha = alpha;
                parentDraw.call(this, params, board);
                this.globalAlpha = 1.0;
            }
        },
    },
};
export default LastRemovedDrawHandler;
